import {
  Button,
  Checkbox,
  Container,
  Grid,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  faker,
  fakerCS_CZ,
  fakerDA,
  fakerDE,
  fakerDE_AT,
  fakerDE_CH,
  fakerEL,
  fakerEN,
  fakerES,
  fakerFI,
  fakerFR,
  fakerFR_BE,
  fakerFR_CH,
  fakerFR_LU,
  fakerHR,
  fakerHU,
  fakerIT,
  fakerLV,
  fakerNB_NO,
  fakerNL,
  fakerPL,
  fakerPT_PT,
  fakerRO,
  fakerSK,
  fakerSR_RS_latin,
  fakerSV,
  fakerTR,
} from "@faker-js/faker";
import { Route, Routes } from "react-router-dom";
import HmacSHA from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="success" element={<Home />} />
      <Route path="failure" element={<Home />} />
    </Routes>
  );
}

const getDefaultEnv = () => {
  let defaultEnv = process.env.REACT_APP_DEFAULT_ENV;

  if (defaultEnv === undefined) {
    defaultEnv = "local";
  }

  return defaultEnv;
};

class Environment {
  name: string;
  appUrl: string;
  childAppUrl: string;
  hostName: string;
  salesPartner: Array<{ uuid: string; name: string }>;

  constructor(
    name: string,
    appUrl: string,
    childAppUrl: string,
    hostName: string,
    salesPartner: Array<{ uuid: string; name: string }>
  ) {
    this.name = name;
    this.appUrl = appUrl;
    this.childAppUrl = childAppUrl;
    this.hostName = hostName;
    this.salesPartner = salesPartner;
  }
}

const envs: Array<Environment> = [
  {
    name: "local",
    appUrl: "http://localhost:3005",
    childAppUrl: "http://localhost:3000",
    hostName: "http://localhost:8004",
    salesPartner: [
      { uuid: "cd78d3b7-d65f-42d0-ae14-13b647777202", name: "ison" },
      {
        uuid: "34ed4147-4cbd-4e7d-8abe-3aabe6f17a37",
        name: "SomeOtherAffiliate",
      },
    ],
  },
  {
    name: "dev",
    appUrl: "https://launch.gclb.energy",
    childAppUrl: "https://enable.dev.ison-energy.com",
    hostName: "https://calculate.dev.ison-energy.com",
    salesPartner: [
      { uuid: "cd78d3b7-d65f-42d0-ae14-13b647777202", name: "ison" },
      {
        uuid: "34ed4147-4cbd-4e7d-8abe-3aabe6f17a37",
        name: "SomeOtherAffiliate",
      },
    ],
  },
  {
    name: "stage",
    appUrl: "https://launch.gclb.energy",
    childAppUrl: "https://enable.stage.ison-energy.com",
    hostName: "https://calculate.stage.ison-energy.com",
    salesPartner: [
      { uuid: "a6918cc3-8355-472a-b89d-87915aeecc75", name: "ison" },
      {
        uuid: "36ed6a78-cda9-4189-85b5-52f13a4e577a",
        name: "SomeOtherAffiliate",
      },
    ],
  },
  {
    name: "prod",
    appUrl: "https://launch.gclb.energy",
    childAppUrl: "https://enable.ison.energy",
    hostName: "https://calculate.prod.ison-energy.com",
    salesPartner: [
      { uuid: "4bc0c713-fdf0-402c-a9a3-f6f8ac7c52ed", name: "ison" },
    ],
  },
];

const Home = () => {
  const [language, setLanguage] = useState("");

  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [yearlyConsumptionKwh, setYearlyConsumptionKwh] = useState(0);
  const [yearlyGridSupplyKwh, setYearlyGridSupplyKwh] = useState(0);
  
  const [pvInstalled, setPvInstalled] = useState(false);
  const [batteryInstalled, setBatteryInstalled] = useState(false);
  const [smartMeterInstalled, setSmartMeterInstalled] = useState(false);
  const [wallboxInstalled, setWallboxInstalled] = useState(false);
  const [heatpumpInstalled, setHeatpumpInstalled] = useState(false);
  const [pvPowerMaxKwp, setPvPowerMaxKwp] = useState(0);
  const [marketLocationId, setMarketLocationId] = useState("");
  const [meterNumber, setMeterNumber] = useState("");
  const [batterySizeKwh, setBatterySizeKwh] = useState(0);
  const [pvInstallationDate, setPvInstallationDate] = useState(new Date());

  const [secret, setSecret] = useState("");
  const [siteCorrelationId, setSiteCorrelationId] = useState("");
  const [salesPartnerUUID, setSalesPartnerUUID] = useState("");
  const [shouldSkipSaleProcess, setShouldSkipSaleProcess] = useState(false);
  const [env, setEnv] = useState(
    envs.find((env) => env.name === getDefaultEnv()) as Environment
  );
  const [callbackUrlSuccess, setCallbackUrlSuccess] = useState(
    env.appUrl + "/success"
  );
  const [callbackUrlFailure, setCallbackUrlFailure] = useState(
    env.appUrl + "/failure"
  );
  const [theme, setTheme] = useState("default");

  const fakerEurope = faker.helpers.arrayElement([
    fakerCS_CZ,
    fakerDA,
    fakerDE,
    fakerDE_AT,
    fakerDE_CH,
    fakerEL,
    fakerEN,
    fakerES,
    fakerFI,
    fakerFR,
    fakerFR_BE,
    fakerFR_CH,
    fakerFR_LU,
    fakerHR,
    fakerHU,
    fakerIT,
    fakerLV,
    fakerNB_NO,
    fakerNL,
    fakerPL,
    fakerPT_PT,
    fakerRO,
    fakerSK,
    fakerSR_RS_latin,
    fakerSV,
    fakerTR,
  ]);

  const generateRandomData = () => {
    setLanguage(fakerEurope.location.countryCode());

    setFirstName(fakerEurope.person.firstName());
    setLastName(fakerEurope.person.lastName());
    setCountry(faker.location.country());
    setZipcode(faker.location.zipCode("#####"));
    setCity(faker.location.city());
    setStreet(faker.location.city());
    setStreetNumber(fakerDE.location.buildingNumber());
    setMobilePhone("+491" + faker.string.numeric(10));
    setEmailAddress(faker.internet.email());
    setYearlyConsumptionKwh(
      faker.number.float({ min: 1000, max: 10000, fractionDigits: 2 })
    );
    setYearlyGridSupplyKwh(
      faker.number.float({ min: 1000, max: 10000, fractionDigits: 2 })
    );

    setPvInstalled(faker.datatype.boolean());
    setBatteryInstalled(faker.datatype.boolean());
    setSmartMeterInstalled(faker.datatype.boolean());
    setWallboxInstalled(faker.datatype.boolean());
    setHeatpumpInstalled(faker.datatype.boolean());
    setPvPowerMaxKwp(
      faker.number.float({ min: 0.5, max: 20, fractionDigits: 2 })
    );
    setMarketLocationId(faker.string.numeric(11));
    setMeterNumber(faker.string.alphanumeric({ length: { min: 9, max: 20 } }));
    setBatterySizeKwh(
      faker.number.float({ min: 0.5, max: 15, fractionDigits: 2 })
    );
    setPvInstallationDate(
      faker.date.past({ years: 30 })
    );
  };

  const [leadUuid, setLeadUuid] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [targetUrl, setTargetUrl] = useState("");
  const [apiGridHidden, setApiGridHidden] = useState(true);

  const updateURLParameter = (url: string, param: string, paramVal: string) => {
    let newAdditionalURL = "";
    let tempArray = url.split("?");
    let baseURL = tempArray[0];
    let additionalURL = tempArray[1];
    let temp = "";
    if (additionalURL) {
        tempArray = additionalURL.split("&");
        for (let i=0; i<tempArray.length; i++){
            if(tempArray[i].split('=')[0] !== param){
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
        }
    }

    let rows_txt = temp + "" + param + "=" + paramVal;
    return baseURL + "?" + newAdditionalURL + rows_txt;
  } 

  const sampleHashCall = async (leave: boolean) => {
    try {
      let payload = JSON.stringify(
        {
          siteCorrelationId: siteCorrelationId,
          uiFlowPreferences: {
            language: language,
            callbackUrlSuccess: callbackUrlSuccess,
            callbackUrlFailure: callbackUrlFailure,
            skipSaleProcess: shouldSkipSaleProcess,
          },
          siteTechnicalData: {
            pvInstalled: pvInstalled,
            pvPowerMaxKwp: pvPowerMaxKwp,
            smartMeterInstalled: smartMeterInstalled,
            marketLocationId: marketLocationId,
            meterNumber: meterNumber,
            batteryInstalled: batteryInstalled,
            batterySizeKwh: batterySizeKwh,
            wallboxInstalled: wallboxInstalled,
            heatpumpInstalled: heatpumpInstalled,
            pvInstallationDate: pvInstallationDate.toISOString().split("T")[0],
          },
          userProfileData: {
            firstname: firstname,
            lastname: lastname,
            country: country,
            zipcode: zipcode,
            city: city,
            street: street,
            streetNumber: streetNumber,
            mobilePhone: mobilePhone,
            emailAddress: emailAddress,
            yearlyConsumptionKwh: yearlyConsumptionKwh,
          },
        },
        (k, v) =>
          v === null || v === "" || v === 0 || v === false ? undefined : v
      );

      let hash = HmacSHA(payload, secret);
      let messageSignature = Base64.stringify(hash);

      let url = `${env.hostName}/api/v1/lead/transfer/${salesPartnerUUID}`;
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Message-Signature": messageSignature,
        },
        body: payload,
      });
      const result = await response.text();
      console.log(result);
      const json = result === "" ? {} : JSON.parse(result);
      if (json.leadUuid === undefined) {
        setApiResponse(JSON.stringify(json, undefined, 2));
        setApiGridHidden(false);
        return;
      }
      if (theme !== "default") {
        json.targetUrl = updateURLParameter(json.targetUrl, "theme", theme);
      }
      if (leave) {
        console.log("redirect url json.targetUrl");
        window.location.href = `${json.targetUrl}`;
      } else {
        setLeadUuid(json.leadUuid);
        setTargetUrl(json.targetUrl);
        let target_url = `${env.hostName}/api/v1/lead/transfer/${salesPartnerUUID}?transfer_id=${json.leadUuid}`;
        console.log(target_url);

        let new_response = await fetch(target_url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        const new_result = await new_response.text();
        console.log(new_result);
        let parsedResult = JSON.parse(new_result);
        if (parsedResult === undefined) {
          console.log("foo");
        }
        setApiResponse(JSON.stringify(parsedResult, undefined, 2));
        setApiGridHidden(false);
      }
    } catch (e) {}
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight={"bold"}>
            OEM Launcher
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={generateRandomData}
          >
            Generate Random Data
          </Button>
        </Grid>
        <Grid item xs={3}>
          <InputLabel id="environment">Environment</InputLabel>
          <Select
            labelId="environment"
            label="Target Calculator"
            size="small"
            value={envs.indexOf(env) as unknown as HTMLInputElement}
            onChange={(e: SelectChangeEvent<HTMLInputElement>) =>
              setEnv(envs[e.target.value as unknown as number])
            }
          >
            {envs.map(function (env, i) {
              return <MenuItem value={i}>{env.name}</MenuItem>;
            })}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <InputLabel id="theme">Theme</InputLabel>
          <Select
            labelId="theme"
            label="Theme"
            size="small"
            value={theme}
            onChange={(e) => setTheme(e.target.value)}
          >
            <MenuItem value="default">default</MenuItem>
            <MenuItem value="ison">ison</MenuItem>
            <MenuItem value="disco">Disco</MenuItem>
            <MenuItem value="sma">SMA</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={"bold"}>
            uiFlowPreferences
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <ListItemText>language: </ListItemText>
          <TextField
            size="small"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>callbackUrlSuccess: </ListItemText>
          <TextField
            size="small"
            placeholder=""
            value={callbackUrlSuccess}
            onChange={(e) => setCallbackUrlSuccess(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>callbackUrlFailure: </ListItemText>
          <TextField
            size="small"
            placeholder=""
            value={callbackUrlFailure}
            onChange={(e) => setCallbackUrlFailure(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={"bold"}>
            userProfileData
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <ListItemText>firstname: </ListItemText>
          <TextField
            size="small"
            value={firstname}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>lastname: </ListItemText>
          <TextField
            size="small"
            value={lastname}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>country: </ListItemText>
          <TextField
            size="small"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>zipcode: </ListItemText>
          <TextField
            size="small"
            value={zipcode}
            onChange={(e) => setZipcode(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>city: </ListItemText>
          <TextField
            size="small"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>street: </ListItemText>
          <TextField
            size="small"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>streetNumber: </ListItemText>
          <TextField
            size="small"
            value={streetNumber}
            onChange={(e) => setStreetNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>mobilePhone: </ListItemText>
          <TextField
            size="small"
            value={mobilePhone}
            onChange={(e) => setMobilePhone(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>emailAddress: </ListItemText>
          <TextField
            size="small"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>yearlyConsumptionKwh (unused): </ListItemText>
          <TextField
            size="small"
            type="number"
            value={yearlyConsumptionKwh}
            onChange={(e) =>
              setYearlyConsumptionKwh(parseFloat(e.target.value))
            }
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>yearlyGridSupplyKwh: </ListItemText>
          <TextField
            size="small"
            type="number"
            value={yearlyGridSupplyKwh}
            onChange={(e) =>
              setYearlyGridSupplyKwh(parseFloat(e.target.value))
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={"bold"}>
            siteTechnicalData
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <ListItemText>pvInstalled: </ListItemText>
          <Checkbox
            size="small"
            checked={pvInstalled}
            onChange={(e) => setPvInstalled(e.target.checked)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>wallboxInstalled: </ListItemText>
          <Checkbox
            size="small"
            checked={wallboxInstalled}
            onChange={(e) => setWallboxInstalled(e.target.checked)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>batteryInstalled: </ListItemText>
          <Checkbox
            size="small"
            checked={batteryInstalled}
            onChange={(e) => setBatteryInstalled(e.target.checked)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>heatpumpInstalled: </ListItemText>
          <Checkbox
            size="small"
            checked={heatpumpInstalled}
            onChange={(e) => setHeatpumpInstalled(e.target.checked)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>smartMeterInstalled: </ListItemText>
          <Checkbox
            size="small"
            checked={smartMeterInstalled}
            onChange={(e) => setSmartMeterInstalled(e.target.checked)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>pvPowerMaxKwp: </ListItemText>
          <TextField
            type="number"
            size="small"
            value={pvPowerMaxKwp}
            onChange={(e) => setPvPowerMaxKwp(parseFloat(e.target.value))}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>marketLocationId: </ListItemText>
          <TextField
            size="small"
            value={marketLocationId}
            onChange={(e) => setMarketLocationId(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>meterNumber: </ListItemText>
          <TextField
            size="small"
            value={meterNumber}
            onChange={(e) => setMeterNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>batterySizeKwh: </ListItemText>
          <TextField
            type="number"
            size="small"
            value={batterySizeKwh}
            onChange={(e) => setBatterySizeKwh(parseFloat(e.target.value))}
          />
        </Grid>

        <Grid item xs={12}>
          <ListItemText>Pv Installation Date: </ListItemText>
          <TextField
            type="date"
            size="small"
            value={pvInstallationDate.toISOString().split("T")[0]}
            onChange={(e) => setPvInstallationDate(new Date(e.target.value))}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={"bold"}>
            Meta Data
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ListItemText>siteCorrelationId: </ListItemText>
          <TextField
            size="small"
            placeholder=""
            onChange={(e) => setSiteCorrelationId(e.target.value)}
          />
        </Grid>

        <Grid item xs={4}>
          <ListItemText>Secret: </ListItemText>
          <TextField
            size="small"
            placeholder="default-hash-secret"
            onChange={(e) => setSecret(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <ListItemText>Sales-Partner: </ListItemText>
          <TextField
            size="small"
            placeholder="sales-partner-uuid"
            value={salesPartnerUUID}
            onChange={(e) => setSalesPartnerUUID(e.target.value)}
          />
          <Select
            label="Predefined Sales-Partners"
            size="small"
            value={salesPartnerUUID}
            onChange={(e) => setSalesPartnerUUID(e.target.value)}
          >
            {env.salesPartner.map(function (salesPartner, _) {
              return (
                <MenuItem value={salesPartner.uuid}>
                  {salesPartner.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <ListItemText>Should skip Sale Process: </ListItemText>
          <Checkbox
            size="small"
            checked={shouldSkipSaleProcess}
            onChange={(e) => setShouldSkipSaleProcess(e.target.checked)}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={() => sampleHashCall(true)}
          >
            Go to Calculator with POST
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={() => sampleHashCall(false)}
          >
            Save & Get data from Calculator API
          </Button>
        </Grid>
        <Grid id="show_result" hidden={apiGridHidden}>
          <Typography variant="h5" fontWeight={"bold"}>
            Show Data from calculator for lead_id {leadUuid}
          </Typography>
          <Typography>
            Redirect URL:{" "}
            <Link target="_blank" href={targetUrl}>
              {targetUrl}
            </Link>
          </Typography>
          <pre>{apiResponse}</pre>
        </Grid>
      </Grid>
    </Container>
  );
};
